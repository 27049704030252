import { appVersion } from './app-version';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  env: 'integration',
  version: appVersion,
  auth: {
    appId: 'vicare-thermostat-codes',
    baseUrl: 'https://api-integration.viessmann.com',
    registerBaseUrl: 'https://account-integration.viessmann.com',
  },
  localization: {
    projectId: '9db913139d42b107b9525a4ef901c617',
    baseUrl: 'https://api.viessmann.com/translations/v2/',
  },
  usersUrl: 'https://api-integration.viessmann.com/users/v1/users/me',
  apiUrl: 'https://api-integration.viessmann.com/',
  http: {
    equipmentBaseUrl: 'https://api-integration.viessmann.com/equipment/v3',
  },
};
